const formatDate = (date: string) => {
  if (!date) return "UNKNOWN DATE";

  const cleanedDate = date.replace(/-/g, "/"); // date format with hyphens is not supported in safari: replace "-" with "/"

  const dateTime = new Date(cleanedDate);
  const newDateTime = dateTime.toLocaleDateString("en-GB", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
  return newDateTime;
};

export default formatDate;
