import { useEffect, useState, useCallback } from "react";

import { Storyblok } from "@/base/lib/storyblok";

export function useFeaturedPost(uuid: string, language: Language) {
  const [featuredPost, setFeaturedPost] =
    useState<ParsedStoryblokStory<PostTemplate>>();
  const url = uuid ? `cdn/stories/${uuid}` : "cdn/stories/";
  const params = uuid
    ? {
        version: "published",
        find_by: "uuid",
      }
    : {
        starts_with: `${language}/news/`,
        version: "published",
        sort_by: "content.post_date:desc",
      };

  const getFeaturedPost = useCallback(
    async function getFeaturedPost() {
      await Storyblok.get(url, params)
        .then(({ data }) => {
          if (uuid) {
            if (data.story) {
              return setFeaturedPost(data.story);
            }
            return null;
          } else {
            if (data.stories) {
              return setFeaturedPost(data.stories[0]);
            }
            return null;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
    [uuid, language]
  );

  useEffect(() => {
    getFeaturedPost();
  }, [uuid, language, getFeaturedPost]);

  return featuredPost;
}
